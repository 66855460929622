import * as React from "react"

import { Link } from "gatsby"

import * as entities from "entities"

/**
 * Displays an excerpt of a blog post. Appearance is similar to a full post but
 * the entire thing is linked to the actual post, so internal links mostly won't
 * work.
 */

export interface IBlogExcerpt {
  fields: {
    slug: string
    categorySlugs: {
      name: string
    }[]
    tagSlugs: {
      name: string
    }[]
    excerpt: string
    authorSlug: string
  }
  frontmatter: {
    author: string
    date: string
    prettyDate: string
    title: string
  }
}

interface IProps {
  node: IBlogExcerpt
}

// tslint:disable-next-line:no-suspicious-comment
// FIXME(steve): Can we narrow down the tslint exclusion without breaking rendering?

export default (props: IProps) => {
  // tslint:disable:react-no-dangerous-html
  const {fields, frontmatter} = props.node
  return (
    <Link to={fields.slug} className="block py-4">
      <article className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            {fields.categorySlugs.map((cat, idx) => {
              return (
                <span key={idx} className="inline-flex pr-2 text-sm leading-5 font-medium text-indigo-600">
                  {cat.name}
                </span>
              )
            })}
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
              {entities.decodeHTML5(frontmatter.title)}
            </h3>
            <div>
              {fields.tagSlugs.map((tag, idx) => {
                return (
                  <span
                    key={idx}
                    className="inline-flex items-center mx-1 px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-purple-100 text-purple-800"
                  >
                  {entities.decodeHTML5(tag.name)}
                  </span>
                )
              })}
            </div>
            <div
              className="blog mt-3 text-base leading-6 text-gray-500"
              dangerouslySetInnerHTML={{ __html: fields.excerpt }}
            />
          </div>
          <div className="mt-6 flex items-center">
            <div className="flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt={frontmatter.author}
              />
            </div>
            <div className="ml-3">
              <p className="text-sm leading-5 font-medium text-gray-900">
                {frontmatter.author}
              </p>
              <div className="flex text-sm leading-5 text-gray-500">
                <time dateTime={frontmatter.date}>
                  {frontmatter.prettyDate}
                </time>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Link>
  )
  // tslint:enable:react-no-dangerous-html
}