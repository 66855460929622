import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/index-layout"
import Excerpt, {IBlogExcerpt} from "../components/blog/excerpt"

interface IData {
  allMdx: {
    edges: {
      node: IBlogExcerpt
    }[]
  }
}

/**
 * Displays excerpts for every post with a given tag
 * @param props
 * @constructor
 */

const CategoryList = ( { data, pageContext}: { data: IData, pageContext: any} ) => (
  <Layout>
    <h1 className="text-2xl">{pageContext.title}</h1>
    {data.allMdx.edges.map((edge, idx) => (
      <Excerpt key={idx} node={edge.node}/>
    ))}
  </Layout>
)

//language=graphql
export const query = graphql`
    query byTag($author: String, $limit: Int, $skip: Int) {
        allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {fields: {collection: {eq: "posts"}, authorSlug: {eq: $author}}}, limit: $limit, skip: $skip) {
            edges {
                node {
                    fields {
                        excerpt
                        tagSlugs {
                            name
                        }
                        categorySlugs {
                            name
                        }
                        slug
                    }
                    frontmatter {
                        author
                        date
                        title
                    }
                }
            }
        }
    }
    `

export default CategoryList



